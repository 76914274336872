export const sjf = (job, arrivalTime, burstTime, priority) => {

  const proccessedJob = [];
  const timeline = [];
  const readyQueue = [];

  const processesInfo = arrivalTime
    .map((item, index) => {
      return {
        job: job[index],
        prior: priority[index],
        at: item,
        bt: burstTime[index],
      }
    })
    .sort((obj1, obj2) => {
      if (obj1.at > obj2.at) return 1
      if (obj1.at < obj2.at) return -1
      if (obj1.bt > obj2.bt) return 1
      if (obj1.bt < obj2.bt) return -1
      return 0
    })

  let currentTime = processesInfo[0].at
  const unfinishedJobs = [...processesInfo]

  const remainingTime = processesInfo.reduce((acc, process) => {
    acc[process.job] = process.bt
    return acc
  }, {})

  readyQueue.push(unfinishedJobs[0])
  while (
    Object.values(remainingTime).reduce((acc, cur) => {
      return acc + cur
    }, 0) && unfinishedJobs.length > 0
  ){
    let prevIdle = false
    if ( readyQueue.length === 0 && unfinishedJobs.length > 0 ) {
      prevIdle = true
      readyQueue.push(unfinishedJobs[0])
    }

    readyQueue.sort((a, b) => {
      if (remainingTime[a.job] > remainingTime[b.job]) return 1
      if (remainingTime[a.job] < remainingTime[b.job]) return -1
      return 0
    })

    const processToExecute = readyQueue[0]

    const processAtLessThanBT = processesInfo.filter((p) => {
      let curr = currentTime
      if (prevIdle) {
        curr = processToExecute.at
      }

      return (
        p.at <= remainingTime[processToExecute.job] + curr &&
        !readyQueue.includes(p) &&
        p !== processToExecute &&
        unfinishedJobs.includes(p)
      )
    })

    let gotInterruption = false
    processAtLessThanBT.some((p) => {
      if (prevIdle) {
        currentTime = processToExecute.at
      }
      
      const amount = p.at - currentTime;
      if (currentTime >= p.at) {
        readyQueue.push(p)
      }

      if (p.bt < remainingTime[processToExecute.job] - amount) {
        remainingTime[processToExecute.job] -= amount
        readyQueue.push(p)
        const prevCurrentTime = currentTime
        currentTime += amount
        timeline.push({
          job: processToExecute.job,
          start: prevCurrentTime,
          stop: currentTime
        })
        
        gotInterruption = true
        return true
      }
    })
    
    const processToArrive= processesInfo.filter((p) => {
      return (
        p.at <= currentTime &&
        !readyQueue.includes(p) &&
        p !== processToExecute &&
        unfinishedJobs.includes(p)
      );
    });

    // Push new processes to readyQueue
    readyQueue.push(...processToArrive)

    if (!gotInterruption) {
      if (prevIdle) {
        const remainingT = remainingTime[processToExecute.job]
        remainingTime[processToExecute.job] -= remainingT
        currentTime = processToExecute.at + remainingT

        processAtLessThanBT.forEach(p => {
          if (currentTime >= p.at) {
            readyQueue.push(p)
          }
        })

        timeline.push({
          job: processToExecute.job,
          start: processToExecute.at,
          stop: currentTime
        })
      } else {
        const remainingT = remainingTime[processToExecute.job]
        remainingTime[processToExecute.job] -= remainingT
        const prevCurrentTime = currentTime
        currentTime += remainingT

        processAtLessThanBT.forEach(p => {
          if (currentTime >= p.at && !readyQueue.includes(p)) {
            readyQueue.push(p)
          }
        })

        timeline.push({
          job: processToExecute.job,
          start: prevCurrentTime,
          stop: currentTime
        });
      }      
    }

    // Requeueing (move head/first item to tail/last)
    readyQueue.push(readyQueue.shift());
    
    // When the process finished executing
    if (remainingTime[processToExecute.job] === 0) {
      const indexToRemoveUJ = unfinishedJobs.indexOf(processToExecute);
      if (indexToRemoveUJ > -1) {
        unfinishedJobs.splice(indexToRemoveUJ, 1)
      }
      const indexToRemoveRQ = readyQueue.indexOf(processToExecute);
      if (indexToRemoveRQ > -1) {
        readyQueue.splice(indexToRemoveRQ, 1)
      }

      proccessedJob.push({
        ...processToExecute,
        ft: currentTime,
        tat: currentTime - processToExecute.at,
        wat: currentTime - processToExecute.at - processToExecute.bt,
      })
    }
  }

  // Sort the processes by job name within arrival time
  proccessedJob.sort((obj1, obj2) => {
    if (obj1.at > obj2.at) return 1
    if (obj1.at < obj2.at) return -1
    if (obj1.job > obj2.job) return 1
    if (obj1.job < obj2.job) return -1
    return 0
  })

  return { process: proccessedJob, timeline }
};