<script setup>
/* eslint-disable */
import { onMounted, ref } from 'vue';
import { roundRobin } from '@/algorithm/roundRobin'
import { rr } from '@/algorithm/roundRobin2'
import { sjf } from '@/algorithm/sjf'
import { nsjf } from '@/algorithm/nsjf'
import { nPriority } from '@/algorithm/nPriority'

const tempProcess = ref({
  process: null,
  burst_time: null,
  arrival_time: null,
  priority: null
})

const testData = [
  {
    process: "P0",
    burst_time: 6,
    arrival_time: 0,
    finish_time: 0,
    priority: 3
  },
  {
    process: "P1",
    burst_time: 4,
    arrival_time: 1,
    finish_time: 0,
    priority: 3
  },
  {
    process: "P2",
    burst_time: 6,
    arrival_time: 5,
    finish_time: 0,
    priority: 1
  },
  {
    process: "P3",
    burst_time: 6,
    arrival_time: 6,
    finish_time: 0,
    priority: 1
  },
  {
    process: "P4",
    burst_time: 6,
    arrival_time: 7,
    finish_time: 0,
    priority: 5
  },
  {
    process: "P5",
    burst_time: 6,
    arrival_time: 8,
    finish_time: 0,
    priority: 6
  },
]

const cases = ref([])

function addProcess(){
  cases.value.push(tempProcess.value)  
  tempProcess.value = {
    process: null,
    burst_time: null,
    arrival_time: null,
    finish_time: null,
    priority: null
  }
}

function removeCase(ind){
  cases.value.splice(ind, 1)
}


function loadData(){
  cases.value = testData  
}

const availAlgo = [
  {
    name: 'Non Preemptive Priority',
    val: 'nPriority'
  },{
    name: 'Non Preemptive SJF',
    val: 'nsjf'
  },{
    name: 'SJF',
    val: 'sjf'
  },{
    name: 'Round Robin',
    val: 'round_robin'
  }
]

const selectedAlgo = ref(null)

function calculateAverage(name){
  let total = 0
  if (isCalculated.value){
    if (name == 'waiting_time'){
      total = results.value.reduce((a,b)=>a + b.waiting_time, 0)
    }else{
      total = results.value.reduce((a,b)=>a + b.turnaround_time, 0)
    }
    let average = total/results.value.length
    return average.toFixed(2)
  }
  return '-'
}

const results = ref([])
const timeline = ref([])
const timeQuantum = ref()
function runProcess(){
  let result = ''
  const casesArr = cases.value.map(item => ({...item}))
  const { job, arrivalTime, burstTime, priority } = parseRobin(casesArr)
  switch (selectedAlgo.value) {
    case 'nPriority':
      result = nPriority(casesArr)
      break;
    case 'nsjf':
      result = nsjf(job, arrivalTime, burstTime, priority)
      result = parseResult(result)
      break;
    case 'sjf':
      result = sjf(job, arrivalTime, burstTime, priority)
      result = parseResult(result)
      break;
    case 'round_robin':
      console.table(casesArr)
      result = rr(job, arrivalTime, burstTime, timeQuantum.value)
      result = parseResult(result)
      break;
    default:
      break;
  }

  results.value = result.processed
  timeline.value = result.timeline
  isCalculated.value = true
}

function parseResult(result){
  const processed = result.process.map(item => {
    return {
      process: item.job,
      arrival_time: item.at,
      burst_time: item.bt,
      finish_time: item.ft,
      priority: item.prior,
      turnaround_time: item.tat,
      waiting_time: item.wat,
    }
  })
  const timeline = result.timeline.map(item =>{
    return {
      process: item.job,
      value: `${item.start} - ${item.stop}`
    }
  })
  return {processed, timeline}
}

function parseRobin(casesArr){
  const priority = casesArr.map(item => {
    return item.priority
  })
  const arrivalTime = casesArr.map(item => {
    return item.arrival_time
  })
  const burstTime = casesArr.map(item => {
    return item.burst_time
  })
  const job = casesArr.map(item => {
    return item.process
  })
  return {arrivalTime, burstTime, job, priority}
}

const isCalculated = ref(false)
onMounted(()=>{
  // roundRobin(testData)
  cases.value = testData  
  // console.table(testData)
  // nPriority(testData)
  // sjf(testData)
  // nsjf(testData)
})

function calculatedSize(){
  let singleBox = Math.floor(12/timeline.value.length)
  return `basis-${singleBox}/12`
}
</script>

<template>
  <span class="bg-stone-200"></span>
  <div v-auto-animate class="flex">
    <div class="basis-1/2">
      <div class="p-10 pb-3 px-4">
        <div v-auto-animate class="w-full h-max bg-white p-5 rounded-lg">
          <div class="flex items-center mb-3">
            <h3 class="font-bold tracking-wide">Process Case</h3>
            <button class="ml-2 px-4 py-1 rounded-md bg-orange-500 text-white border hover:border-amber-600 hover:bg-amber-100 hover:text-amber-600"
              @click="loadData()"
            >
              Load Test Data
            </button>
          </div>
          <form @submit.prevent="addProcess()" class="flex w-full mb-4">
            <div class="basis-1/5 mx-1">
              <label class="block text-sm font-medium leading-6 text-gray-900">Process</label>
              <input type="text" class="w-full mt-1 block bg-white border border-stone-400 rounded-md py-1.5 pl-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" 
              placeholder="Process"
              required
              v-model="tempProcess.process"
              >
            </div>
            <div class="basis-1/5 mx-1">
              <label class="block text-sm font-medium leading-6 text-gray-900">Burst Time</label>
              <input type="number" class="w-full mt-1 block bg-white border border-stone-400 rounded-md py-1.5 pl-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" 
              placeholder="Burst Time"
              required
              v-model="tempProcess.burst_time"
              >
            </div>
            <div class="basis-1/5 mx-1">
              <label class="block text-sm font-medium leading-6 text-gray-900">Arrival Time</label>
              <input type="number" class="w-full mt-1 block bg-white border border-stone-400 rounded-md py-1.5 pl-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" 
              placeholder="Arrival Time"
              required
              v-model="tempProcess.arrival_time"
              >
            </div>
            <div class="basis-1/5 mx-1">
              <label class="block text-sm font-medium leading-6 text-gray-900">Priority</label>
              <input type="number" class="w-full mt-1 block bg-white border border-stone-400 rounded-md py-1.5 pl-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" 
              placeholder="Priority"
              required
              v-model="tempProcess.priority"
              >
            </div>
            <div class="basis-1/5 mx-2 my-auto mb-1">
              <button type="submit" class="rounded-full bg-green-600 text-white w-8 h-8">+</button>
            </div>
          </form>
          <div v-if="cases.length == 0" class="border border-red-500 bg-red-100 rounded-md text-red-500 py-1 pl-4">
            <p>No case has been added yet</p>
          </div>
          <div v-auto-animate v-else>
            <div v-for="(caseObj, ind) in cases" :key="ind" class="mt-1 flex">
              <div class="basis-1/5 mx-1">
                <input type="text" class="w-full disabled:bg-slate-200 mt-1 block bg-white border border-stone-400 rounded-md py-1.5 pl-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" 
                placeholder="Process"
                required
                disabled
                v-model="caseObj.process"
                >
              </div>
              <div class="basis-1/5 mx-1">
                <input type="number" class="w-full disabled:bg-slate-200 mt-1 block bg-white border border-stone-400 rounded-md py-1.5 pl-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" 
                placeholder="Burst Time"
                required
                disabled
                v-model="caseObj.burst_time"
                >
              </div>
              <div class="basis-1/5 mx-1">
                <input type="number" class="w-full disabled:bg-slate-200 mt-1 block bg-white border border-stone-400 rounded-md py-1.5 pl-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" 
                placeholder="Arrival Time"
                required
                disabled
                v-model="caseObj.arrival_time"
                >
              </div>
              <div class="basis-1/5 mx-1">
                <input type="number" class="w-full disabled:bg-slate-200 mt-1 block bg-white border border-stone-400 rounded-md py-1.5 pl-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" 
                placeholder="Priority"
                required
                disabled
                v-model="caseObj.priority"
                >
              </div>
              <div class="basis-1/5 mx-2 my-auto mb-1">
                <button @click="removeCase(ind)" type="submit" class="rounded-full bg-red-600 text-white w-8 h-8">-</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-10 pt-0 px-4">
        <div v-auto-animate class="w-full h-max bg-white p-5 rounded-lg">
          <h3 class="font-bold tracking-wide">Select Algorithm</h3>
          <label class="block text-sm font-medium leading-6 text-gray-900">Algorithm</label>
          <div v-if="selectedAlgo == 'round_robin'" class="mt-2 flex">
            <div class="basis-1/5">
              <label class="block text-sm font-medium leading-6 text-gray-900">Time Quantum</label>
              <input type="number" class="w-full mt-1 block bg-white border border-stone-400 rounded-md py-1.5 pl-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" 
              placeholder="Time Quantum"
              required
              v-model="timeQuantum"
              >
            </div>
          </div>
          <div class="mt-2 flex">
            <select v-model="selectedAlgo" name="algorithm" class="block w-full rounded-md py-1.5 text-gray-900 shadow-sm border border-zinc-500 sm:max-w-xs sm:text-sm sm:leading-6">
              <option disabled :value="null">Please select algorithm</option>
              <option v-for="algo in availAlgo" :value="algo.val">{{ algo.name }}</option>
            </select>
            <button class="ml-2 text-sm disabled:bg-zinc-400 disabled:text-white disabled:border-0 disabled:cursor-not-allowed cursor-pointer bg-green-600 text-white py-1 px-4 rounded-md hover:bg-lime-200 hover:text-lime-600 border  hover:border-lime-700"
              @click="runProcess()"
              :disabled="cases.length == 0 || selectedAlgo == null"
            >
              Run
            </button>
          </div>
        </div>
      </div>      
    </div>

    <div class="basis-1/2">
      <div class="p-10 px-4">
        <div v-auto-animate class="w-full h-max bg-white p-5 rounded-lg">
          <div class="bg-zinc-100 px-3 py-2 rounded-md d-inline-block">
            <h2 class="font-bold">Output</h2>
          </div>
          <!-- gantt chart -->
          <div class="hidden">
            <span class="basis-1/12"></span>
            <span class="basis-2/12"></span>
            <span class="basis-3/12"></span>
            <span class="basis-4/12"></span>
            <span class="basis-5/12"></span>
            <span class="basis-6/12"></span>
            <span class="basis-7/12"></span>
            <span class="basis-8/12"></span>
            <span class="basis-9/12"></span>
            <span class="basis-10/12"></span>
            <span class="basis-11/12"></span>
            <span class="basis-12/12"></span>
          </div>
          <div class="flex">
            <template v-if="timeline">
              <div v-for="time in timeline" :class="calculatedSize()" class="border border-zinc-600 text-center">
                <p class="-mb-2">{{ time.process }}</p>
                <small class="text-zinc-500">{{ time.value }}</small>
              </div>
            </template>
          </div>

          <!-- calculation table -->
          <div class="relative overflow-x-auto mt-3">
            <table class="w-full text-sm text-left text-gray-500">
              <thead class="text-xs text-gray-700 uppercase bg-gray-200">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    Process
                  </th>
                  <th scope="col" class="px-6 py-3 text-center">
                    Burst Time
                  </th>
                  <th scope="col" class="px-6 py-3 text-center">
                    Arrival Time
                  </th>
                  <th scope="col" class="px-6 py-3 text-center">
                    Priority
                  </th>
                  <th scope="col" class="px-6 py-3 text-center">
                    Finish Time
                  </th>
                  <th scope="col" class="px-6 py-3 text-center">
                    Turnaround Time
                  </th>
                  <th scope="col" class="px-6 py-3 text-center">
                    Waiting Time
                  </th>
                </tr>
              </thead>
              <tbody v-auto-animate>
                <template v-if="results.length !== 0 && isCalculated">
                  <tr v-for="caseObj in results" :key="caseObj.process" class="bg-white border-b">
                    <td class="px-6 py-4">
                      {{ caseObj.process }}
                    </td>
                    <td class="px-6 py-4 text-center">
                      {{ caseObj.burst_time }}
                    </td>
                    <td class="px-6 py-4 text-center">
                      {{ caseObj.arrival_time }}
                    </td>
                    <td class="px-6 py-4 text-center">
                      {{ caseObj.priority }}
                    </td>
                    <td class="px-6 py-4 text-center">
                      {{ caseObj.finish_time }}
                    </td>
                    <td class="px-6 py-4 text-center">
                      {{ caseObj.turnaround_time }}
                    </td>
                    <td class="px-6 py-4 text-center">
                      {{ caseObj.waiting_time }}
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr class="bg-white border-b">
                    <td colspan="6" class="text-center text-lg py-3">
                      Nothing to show at the moment :-[
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>

          <!-- Average thing -->
          <div class="mt-3 border rounded-md p-2 flex justify-around">
            <p>
              <span class="tracking-wide font-semibold text-zinc-500">Average Waiting Time : </span>
              {{ calculateAverage('waiting_time') }}
            </p>
            <p>
              <span class="tracking-wide font-semibold text-zinc-500">Average Turnaround Time : </span>
              {{ calculateAverage('turnaround_time') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
