let nPriorityObj = {
  processes: [],
  current_time: 0,
  current_index: 0,
  ready_que: [],
  timeline: []
}

export function nPriority(processes){
  nPriorityObj = {
    processes: [],
    current_time: 0,
    current_index: 0,
    ready_que: [],
    timeline: []
  } // reset obj

  nPriorityObj.processes = processes.map(item=>({...item}))
  nPriorityObj.processes = nPriorityObj.processes.sort((a, b) => {
    return a.arrival_time - b.arrival_time || a.priority - b.priority
  }) // sort by arrival time and burst time
  let highPriority = null
  while (nPriorityObj.processes.some(item=>item.burst_time > 0)){
    nPriorityObj.processes.forEach(item=>{
      if (item.arrival_time <= nPriorityObj.current_time){
        // console.log("🚀 ~ file: App.vue:99 ~ nPriority ~ item:", item)
        if(!isProcessExist(item)){
          // console.log('shit')
          if (highPriority && highPriority.arrival_time < item.arrival_time){
            nPriorityObj.ready_que.push(item)
          }
          if (nPriorityObj.current_index == 0){
            nPriorityObj.ready_que.push(item)
          }
        }
      }
    })

    // console.log("🚀 ~ file: App.vue:108 ~ nPriority ~ nPriorityObj.ready_que:", nPriorityObj.ready_que)
    // console.log("🚀 ~ file: App.vue:108 ~ nPriority ~ nPriorityObj.ready_que.length:", nPriorityObj.ready_que.length)
    // console.log("🚀 ~ file: App.vue:107 ~ nPriority ~ nPriorityObj.current_index:", nPriorityObj.current_index)
    let topProcess = nPriorityObj.ready_que[nPriorityObj.current_index].burst_time == 0 ? nPriorityObj.ready_que[nPriorityObj.current_index + 1] : nPriorityObj.ready_que[nPriorityObj.current_index]
    // console.log("🚀 ~ file: App.vue:107 ~ nPriority ~ topProcess:", topProcess)
    // highPriority = nPriorityObj.ready_que.find(item => item.priority <= topProcess.priority && item.burst_time !== 0)
    highPriority = findHighestPriority()
    // console.log("🚀 ~ file: App.vue:111 ~ nPriority ~ highPriority:", highPriority)
    if (!highPriority){
      highPriority = topProcess
    }
    nPriorityObj.timeline.push({
      process: highPriority.process,
      value: `${nPriorityObj.current_time} - ${nPriorityObj.current_time+highPriority.burst_time}`
    })
    nPriorityObj.current_index++
    nPriorityObj.current_time += highPriority.burst_time
    highPriority.finish_time = nPriorityObj.current_time
    highPriority.burst_time = 0
  }

  let processed = processes.map(item =>{
    let temp = nPriorityObj.ready_que.find(procObj => item.process == procObj.process)
    const turnaround_time = temp.finish_time - temp.arrival_time
    const waiting_time = turnaround_time - item.burst_time
    return {
      ...item,
      finish_time: temp.finish_time,
      turnaround_time,
      waiting_time
    }
  })  
  console.table(processed)
  return {processed, timeline: nPriorityObj.timeline}
}

function isProcessExist(currenObj){
  return nPriorityObj.ready_que.some(item => item.process == currenObj.process)
}

function findHighestPriority(){
  let highestPrior = null
  let tempArr = nPriorityObj.ready_que.filter(item => item.burst_time !== 0)
  highestPrior = tempArr.reduce((prev, curr)=>{
    return prev.priority <= curr.priority && prev.burst_time !== 0 ? prev : curr
  })
  return highestPrior
}